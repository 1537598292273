.category-products-grid {
  .old-price, .discounted-by {
    display: none;
  }
}

.special-price .price-label {
  display: none;
}

.price-box {
  .old-price {
    display: inline-block;
    margin-right: 10px;
  }

  .special-price {
    display: inline-block;
    font-weight: bold;
  }
}

.the-slideshow-wrapper-outer {
  border: solid 10px #f1f1f1;
  border-width: 0 0 10px 0;
}

.sprayer-type-section {
  text-align: center;
  background-image: linear-gradient(#ffffff, #e4e4e4);
  padding-bottom: 50px;
  border: solid 15px #f1f1f1;
  border-width: 0 0 15px 0;
}

.sprayer-type {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: bottom;
  text-align: center;
  text-transform: uppercase;
  color: #000;
  width: 290px;
  padding-top: 200px;
  font-size: 15pt;
  font-weight: bold;
  line-height: 25px;
  cursor: pointer;
  margin-bottom: 40px;
}

@media all and (min-width: 1171px) {
  .sprayer-type {
    font-size: 12pt;
    width: 220px;
  }
}

@media all and (min-width: 1680px) {
  .sprayer-type {
    font-size: 15pt;
    width: 280px;
  }
}

.slideshow-wrapper {
  width: 100% !important;
}

#algolia-searchbox svg.magnifying-glass, #algolia-autocomplete-container svg.magnifying-glass {
  right: 10px !important;
  top: 10px !important;
}

#algolia-autocomplete-tt.algolia-autocomplete {
  display: inline-block !important;
  width: 100% !important;
}

body #algolia-searchbox .algolia-search-input {
  float: none !important;
  width: 100% !important;
}

#algolia-searchbox {
  label {
    bottom: 12px;
    display: inline-block;
    position: relative;
  }

  margin: 0px !important;
  position: relative !important;
}

body {
  #algolia-autocomplete-container .aa-dropdown-menu {
    z-index: 99999 !important;
    right: auto !important;
    padding-bottom: 20px;
    width: 100% !important;
  }

  #algolia-searchbox .clear-query-autocomplete {
    right: 10px !important;
    top: 15px !important;
  }
}

#algolia-searchbox label {
  display: none !important;
}

.catalog-product-view #qty {
  min-width: 55px;
}

@media only screen and (min-width: 1280px) {
  .header-regular .search-wrapper {
    bottom: auto;
    left: 26%;
    position: absolute;
    right: auto;
    top: 76px;
    width: 55%;
  }

  body #algolia-autocomplete-container .aa-dropdown-menu {
    left: auto !important;
    padding-bottom: 20px;
    right: auto !important;
    top: auto !important;
    z-index: 99999 !important;
    min-width: auto;
  }

  #algolia-autocomplete-container {
    top: 40px !important;
    width: 100%
  }
}

.top-top-search {
  width: 50%;
  #search_mini_form {
    width: 80%;
  }
  @media only screen and (max-width: 1442px) {
    width: 40%;
  }
  @media only screen and (max-width: 1199px) {
    width: 64%;
    #search_mini_form {
      width: 90%;
    }
  }
  @media only screen and (max-width: 990px) {
    width: 53%;
    #search_mini_form {
      width: 90%;
    }
  }
}

@media only screen and (min-width: 759px) and (max-width: 960px) {
  .header-regular .search-wrapper {
    bottom: auto;
    left: 32%;
    position: absolute;
    right: auto;
    top: 76px;
    width: 45%;
  }

  body #algolia-autocomplete-container .aa-dropdown-menu {
    left: auto !important;
    padding-bottom: 20px;
    right: auto !important;
    top: auto !important;
    z-index: 99999 !important;
    min-width: auto;
  }

  #algolia-autocomplete-container {
    left: 32% !important;
    position: absolute !important;
    right: auto !important;
    top: 113px !important;
    width: 65% !important;
  }

  body #algolia-autocomplete-container .autocomplete-wrapper {
    .col9 {
      border-right: medium none;
      float: none;
      width: 100% !important;
    }

    .col3 {
      float: none;
      width: 100% !important;
    }
  }
}

@media only screen and (max-width: 760px) {
  .header-regular .search-wrapper {
    bottom: auto;
    position: absolute;
    right: auto;
    top: 76px;
    width: 45%;
  }

  body #algolia-autocomplete-container .aa-dropdown-menu {
    padding-bottom: 20px;
    right: auto !important;
    top: auto !important;
    z-index: 99999 !important;
    min-width: auto;
  }

  #algolia-autocomplete-container {
    position: absolute !important;
    right: auto !important;
    top: 200px !important;
    width: 75% !important;
  }

  body #algolia-autocomplete-container .autocomplete-wrapper {
    .col9 {
      border-right: medium none;
      float: none;
      width: 100% !important;
    }

    .col3 {
      float: none;
      width: 100% !important;
    }
  }
}

@media only screen and (max-width: 479px) {
  #algolia-autocomplete-container {
    width: 85% !important;
  }
}

body {
  #algolia_instant_selector .facet .name {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0 !important;
    border-bottom: 1px solid #ccc;
    color: #043a70;
    padding: 6px 0;
    text-align: center;
    font-weight: bold;
  }

  #current-refinements .facet {
    padding: 0 !important;
    width: 100% !important;
  }

  #algolia_instant_selector {
    .facet {
      background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
      border: medium none;
    }

    #instant-search-bar {
      border: 1px solid #05386c;
      width: calc(100% - 21px);
    }

    .clear-query-instant {
      cursor: pointer;
      display: block !important;
      position: absolute;
      right: 12px;
      top: 0;
      width: 70px;
    }

    #instant-search-box svg.magnifying-glass {
      fill: #05386c;
      left: auto;
      position: absolute;
      right: 82px;
      stroke: #05386c;
      top: 9px;
    }

    img.clear-query-instant {
      display: none !important;
    }
  }

  .ais-range-slider--connect {
    background: #05386c none repeat scroll 0 0;
    height: 8px;
  }

  .ais-range-slider--background {
    background: #cfeafa none repeat scroll 0 0;
    height: 8px;
  }

  .ais-current-refined-values--item {
    float: left;
    margin-right: 5px;
    margin-top: 8px;
  }
}

.finder-product {
  border-bottom: solid 2px #fff;
  border-top: solid 1px #fff;

  &:hover {
    border-bottom: solid 2px #032750;
    border-top: solid 1px #888;
    background: #f7f7f7 url('/media/icons/circle-arrow.png') no-repeat right 20px center !important;
    background-position: right 20px !important;
  }
}

.finder-product-info {
  top: 5px !important;
}

.finder-product-content {
  .thumb {
    float: left;
    min-height: 100px;
  }

  height: auto !important;

  &:hover {
    text-decoration: none;
  }
}

.finder-product-info {
  color: #000;
  padding-right: 10px;
  padding-bottom: 10px;
}

.finder-product .algoliasearch-autocomplete-price {
  color: #c40000;

  .before_special {
    color: #a0a0a0 !important;
    font-size: 9pt !important;
  }
}

.ais-hits--item {
  border-bottom: 2px solid #fff;
  border-top: 1px solid #fff;
  display: inline-block;
  width: 100%;

  &:nth-child(2n+1) {
    background-color: #fbfbfb;
  }
}

.finder-product-info {
  top: 5px !important;
}

.result-content {
  .result-thumbnail {
    float: left;
    min-height: 100px;
  }

  height: auto !important;
}

.result-content-content:hover {
  text-decoration: none;
}

.result-sub-content {
  color: #000;
  padding-right: 10px;
  padding-bottom: 10px;
}

.ais-hits--item .algoliasearch-autocomplete-price {
  color: #c40000;

  .before_special {
    color: #a0a0a0 !important;
    font-size: 9pt !important;
  }
}

body #algolia_instant_selector .result {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
}

.ais-hits--item .col-sm-6 {
  padding: 0;
  width: 100%;
}

.finder-product-content img {
  margin-right: 15px;
  width: 75px;
}

body #algolia_instant_selector .hits .infos {
  margin: 0;
}

.finder-product {
  padding: 6px;
}

body {
  #algolia-autocomplete-container {
    .aa-dropdown-menu .category {
      padding: 8px 0;
      border: 1px solid #ccc;
      border-left: none;
      border-right: none;
      color: #043a70;
      text-align: left;
      text-align: center;
      font-size: .9em;
      text-transform: uppercase;
      background-color: #f8f8f8;
      font-weight: bold;
    }

    .other-sections {
      margin: 0px 0 25px;
    }

    .aa-dropdown-menu {
      .aa-dataset-products .aa-suggestion {
        width: 100% !important;
        border-bottom: 1px solid #ccc;
      }

      .algoliasearch-autocomplete-price {
        bottom: 0px;
        color: #043a70;
        float: right;
        font-size: 1.1em;
        height: 22px;
        position: relative;
        top: auto;
      }
    }
  }

  .aa-dataset-products .aa-suggestions {
    margin: 0 auto 0px;
  }

  #algolia-autocomplete-container .aa-dropdown-menu {
    .aa-dataset-products .algoliasearch-autocomplete-hit {
      padding: 6px 10px;
    }

    .algoliasearch-autocomplete-hit .info .algoliasearch-autocomplete-category {
      font-size: 12px;
    }
  }
}

.other-sections .aa-dataset-suggestions {
  display: none;
}

body {
  #algolia-autocomplete-container .aa-dropdown-menu .aa-dataset-products .aa-suggestion:last-child {
    border-bottom: none;
  }

  #autocomplete-products-footer {
    padding: 1% !important;
    width: 98% !important;
  }

  .ais-range-slider--value, .ais-range-slider--tooltip {
    font-size: 13px;
    font-weight: bold;
  }
}

#algolia-left-container .facet {
  background-color: white;
  float: left !important;
  margin-bottom: 15px !important;
  margin-right: 2% !important;
  margin-top: 0 !important;
  width: 48% !important;
}

#algolia-autocomplete-container {
  .aa-dropdown-menu {
    min-width: auto !important;
    right: 0 !important;
    width: 71% !important;
  }

  .col9, .col3 {
    border-left: medium none;
    float: none;
    width: 100% !important;
  }

  .aa-dropdown-menu .aa-dataset-products .algoliasearch-autocomplete-hit:hover {
    background: #f7f7f7 none repeat scroll 0 0;
  }
}

@media only screen and (max-width: 771px) {
  #algolia-autocomplete-container .aa-dropdown-menu {
    width: 100% !important;

    .other-sections .aa-suggestions {
      margin-bottom: 0px;
    }
  }

  body #algolia-autocomplete-container .other-sections {
    margin-bottom: 0px;
  }
}

body .addto-links-icons .ic {
  background-color: #0974b4;
}

.ic-heart {
  background-color: black !important;
  background-image: url("/media/icons/ic-heart.png") !important;
  background-position: center center;
  background-repeat: no-repeat !important;
  background-size: auto auto;

  &::before {
    content: none;
  }
}

.special-price .price-label {
  display: none;
}

.price-box {
  .old-price {
    float: left;
    margin-right: 10px;
  }

  .configurable-price-from-label {
    display: none;
  }

  .special-price {
    float: left;
    font-weight: bold;
  }
}

.ib.icon-color-productview, span.icon.icon-color-productview {
  background-color: #0974b4;
}

.yotpo-small-box .yotpo-logo-title {
  + .yotpo-icon-btn-big, a {
    display: none;
  }

  &::before {
    color: #3085c7 !important;
    content: "Reviews by";
  }

  &::after {
    color: #3085c7 !important;
    content: "\e609";
    font-family: "yotpo-widget-font" !important;
    font-size: 30px;
    line-height: 46px;
    vertical-align: middle;
  }
}

.block-layered-nav .block-content > dl > dt {
  cursor: pointer;
}

.product-view .product-type-data {
  > .price-box {
    border-top-style: solid;
    border-top-width: 1px;
    display: inline-block;
    margin: 0;
    padding: 15px 0px 10px 0;
    width: 100%;
  }

  .availability {
    left: auto;
    position: relative;
    text-align: right;
    top: 35px;
    margin: 0;
  }
}

#checkoutSteps .field {
  &.name-middlename {
    display: none;
  }

  &.name-firstname {
    width: 275px;

    input.input-text {
      width: 262px;
    }
  }
}

#narrow-by-list dt {
  &:hover {
    background: #eada06;
    color: #555;
  }

  background: #f3e52c none repeat scroll 0 0;
  display: inline-block;
  font-size: 13px;
  margin-top: 12px;
  padding: 10px 3%;
  width: 94%;

  &.active::after {
    -ms-transform: rotate(90deg);

    /* IE 9 */
    -webkit-transform: rotate(90deg);

    /* Chrome, Safari, Opera */
    transform: rotate(90deg);
    display: block !important;
    right: 0px;
    top: 15px;
  }

  &::after {
    border: medium none;
    content: "\276e" !important;
    display: block !important;
    font-size: 23px;
    left: auto;
    position: absolute;
    right: 37px;
    top: 29px;
    -ms-transform: rotate(270deg);

    /* IE 9 */
    -webkit-transform: rotate(270deg);

    /* Chrome, Safari, Opera */
    transform: rotate(270deg);
  }
}

@media only screen and (min-width: 767px) and (max-width: 960px) {
  #narrow-by-list dt {
    font-size: 12px;
  }

  .col-left {
    > *, .widget-static-block {
      margin-right: 18px;
    }
  }

  #narrow-by-list dt {
    &::after {
      font-size: 17px;
      right: 23px;
      top: 27px;
    }

    &.active::after {
      right: 5px;
      top: 17px;
    }
  }
}

.tier-prices li {
  font-size: 16px !important;
  line-height: 23px;
  background-position: 0px 8px;
}

#nav .nav-panel--dropdown a {
  text-align: center;
}

@media only screen and (min-width: 1281px) and (max-width: 1361px) {
  body .nav-regular .nav-item.level0 > a, .nav-regular .nav-holder .dropdown-heading, .nav-holders-wrapper-mobile .nav-holder .dropdown .dropdown-heading {
    font-size: 15px !important;
    padding: 4px !important;
  }
}

.sticky-header .nav-regular {
  .nav-item.level0 > a, .nav-holder .dropdown-heading {
    font-size: 14px !important;
  }

  .nav-item--homeicon {
    a, &.active a {
      font-size: 14px !important;
    }
  }
}

@media only screen and (max-width: 960px) {
  body .nav-regular .nav-item.level0 > a, .nav-regular .nav-holder .dropdown-heading, .nav-holders-wrapper-mobile .nav-holder .dropdown .dropdown-heading {
    font-size: 12px !important;
  }

  .sticky-header .nav-regular {
    .nav-item.level0 > a, .nav-holder .dropdown-heading {
      font-size: 10px !important;
    }

    .nav-item--homeicon {
      a, &.active a {
        font-size: 10px !important;
      }
    }
  }
}

@media only screen and (min-width: 959px) and (max-width: 1280px) {
  body .nav-regular .nav-item.level0 > a, .nav-regular .nav-holder .dropdown-heading, .nav-holders-wrapper-mobile .nav-holder .dropdown .dropdown-heading {
    font-size: 12px !important;
  }

  .sticky-header .nav-regular {
    .nav-item.level0 > a, .nav-holder .dropdown-heading {
      font-size: 10px !important;
    }

    .nav-item--homeicon {
      a, &.active a {
        font-size: 10px !important;
      }
    }
  }
}

.apptrian-subcategories-grid .apptrian-subcategories-category-name {
  font-size: 17px;
  color: #000000;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 7px;
}

@media only screen and (min-width: 1281px) {
  .apptrian-subcategories-grid li {
    width: 16%;
  }
}

.category-products {
  .sorter {
    background: #ededed none repeat scroll 0 0;
    margin-top: 10px;
    padding: 8px;
  }

  .toolbar {
    border: medium none;
  }
}

.page-title.category-title {
  border: medium none;
}

.category-products-grid {
  .old-price, .discounted-by {
    display: none;
  }

  .special-price {
    float: none;
    font-size: 18px;
  }
}

#checkout-step-login input {
  &[type=radio] {
    transform: scale(1.5, 1.5);
    -moz-transform: scale(1.5, 1.5);
    -ms-transform: scale(1.5, 1.5);
    -webkit-transform: scale(1.5, 1.5);
    -o-transform: scale(1.5, 1.5);
    margin-top: 7px;
    margin-bottom: 11px;
  }

  + label {
    font-size: 16px;
    padding-left: 5px;
  }
}

#cart-sidebar {
  .product-image img {
    display: table;
  }

  .product-details .product-name a {
    text-indent: 0px !important;
    display: table;
  }
}

.sp-methods {
  br {
    display: none;
  }

  .shipping-rate {
    padding-bottom: 10px;
  }
}

.instant-search-bar-label {
  display: none;
}

.algolia-instant-results-wrapper {
  margin: auto;
}

//@media only screen and (min-width: 1280px) {
  .header-regular .search-wrapper {
    bottom: auto;
    left: 10px;
    position: relative;
    right: auto;
    top: 6px;
    width: 100%;
  }
  .header-regular .header-top .form-search .search-autocomplete {
    top: 0 !important;
  }

//}

.awislider {
  margin: 0;
  border: none;
  box-shadow: inherit;
  -webkit-box-shadow: inherit;
  border-radius: inherit;
  -webkit-border-radius: inherit;
}

.menu-image-icon {
  vertical-align: middle;
  margin-right: 10px;
}

.header-regular {
  #user-menu-wrapper-regular {
    display: none;
  }
}

@media only screen and (max-width: 599px) {
  #bss_configurablegridview thead {
    display: table-header-group !important;
  }
}